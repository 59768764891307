/* Agregar desplazamiento suave */
html {
  scroll-behavior: smooth;
}

/* Estilos para la sección Nosotros */
#nosotros {
  background-color: #f8f8f8; /* Fondo gris claro */
  padding: 60px 0; /* Espaciado superior e inferior */
}

/* Estilos para la imagen en la sección Nosotros */
.nosotros-img {
  object-fit: cover; /* Ajusta la imagen para que ocupe el espacio sin deformarse */
  object-position: center; /* Centra la imagen dentro del contenedor */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Sombra suave */
  width: 100%; /* Ocupa el 100% del ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  margin: 0 auto 20px auto; /* Centra la imagen horizontalmente y añade margen inferior */
  display: block; /* Asegura que la imagen sea un bloque para aplicar márgenes automáticamente */
}

/* Estilos para el texto en la sección Nosotros */
#nosotros h2 {
  color: #333; /* Color del título */
  font-size: 40px; /* Tamaño de fuente grande */
  text-align: center; /* Alineación centrada */
  margin-bottom: 20px; /* Margen inferior */
}

#nosotros p {
  color: #666; /* Color del texto */
  background-color: #ffffff; /* Fondo blanco */
  padding: 15px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); /* Sombra suave */
  line-height: 1.6; /* Altura de línea */
  font-size: 18px; /* Tamaño de fuente */
  text-align: center; /* Alineación centrada */
}

/* Media Queries para pantallas de menos de 768px (dispositivos móviles) */
@media (max-width: 767px) {
  .nosotros-img {
    max-height: 300px; /* Altura máxima menor en móviles */
    object-position: center top; /* Ajusta la posición de la imagen en móviles para mostrar la parte superior */
  }

  .col-md-6 {
    width: 100%; /* Ocupa el 100% del ancho del contenedor en móviles */
    margin-bottom: 20px; /* Añade un margen en la parte inferior para separar texto e imagen */
  }
}

/* Media Queries para pantallas grandes */
@media (min-width: 768px) and (max-width: 1199px) {
  .nosotros-img {
    max-height: 400px; /* Altura máxima menor en notebooks y tablets */
  }
}

@media (min-width: 1200px) {
  .nosotros-img {
    max-height: 500px; /* Altura máxima para pantallas de escritorio grandes */
  }
}

/* index.css */
/* Agregar desplazamiento suave */
html {
  scroll-behavior: smooth;
}


/* Estilos globales para toda la aplicación */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Agrega el desplazamiento suave */
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  background: #303030;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  overflow-x: hidden;
}

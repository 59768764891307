/* Archivo: src/App.css */

/* Colores para las secciones */
.home-section {
    background: #f5f5f5; /* Color para la sección Home */
}

.nosotros-section {
    background: #ffe4e1; /* Color para la sección Nosotros */
}

.servicio-section {
    background: #303030; /* Color para la sección Servicio */
}

.turno-section {
    background: #ffffff; /* Color para la sección Turno */
}

.administracion-section {
    background: #303030; /* Color para la sección Administración */
}

.planificacion-section {
    background: #ffffff; /* Color para la sección Planificación */
}

.footer-section {
    background: #303030; /* Color para la sección Footer */
    color: #ffffff; /* Color de texto en el Footer */
}

/* Contenedor para Header y Navbar */
.header-navbar-container {
    display: flex;
    flex-direction: column;
}

/* Estilos generales del Header */
.header-section {
    background-color: #303030; /* Color de fondo del Header */
    color: #ffffff; /* Color del texto */
    padding: 10px;
}

.header-content {
    display: flex;
    justify-content: space-between; /* Alineación horizontal en pantallas grandes */
    align-items: center;
    flex-wrap: wrap; /* Permite que los elementos se muevan a la siguiente línea si es necesario */
}

.header-text {
    margin: 5px 0;
}

/* Estilos para pantallas grandes (PC) */
@media (min-width: 769px) {
    .header-section {
        order: 1; /* El Header se muestra primero en pantallas grandes */
    }

    .navbar-section {
        order: 2; /* El Navbar se muestra después en pantallas grandes */
    }
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
    .header-navbar-container {
        flex-direction: column;
    }

    .header-section {
        order: 2; /* El Header se muestra después en móviles */
    }

    .navbar-section {
        order: 1; /* El Navbar se muestra primero en móviles */
    }

    .header-content {
        flex-direction: column; /* Alinea los elementos en una columna en pantallas pequeñas */
        text-align: center;
    }

    .header-text {
        margin: 10px 0; /* Espacio entre los textos en pantallas pequeñas */
    }
}

/* Estilos personalizados para Turno */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.turno-section {
  padding: 20px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif; /* Asegura que la tipografía se mantenga consistente */
}

.card {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #333;
  font-weight: bold;
  font-size: 1.5rem;
}

.card-text {
  color: #666;
  font-size: 1rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.modal-content {
  border-radius: 10px;
}

.modal-title {
  color: #333;
  font-weight: bold;
  font-size: 1.25rem;
}

.modal-body p {
  color: #666;
  font-size: 1rem;
}

.modal-body ul {
  padding-left: 20px;
}

.modal-body ul li {
  color: #666;
  font-size: 1rem;
}

.enlace-tarifario {
  color: #007bff;
  text-decoration: none;
}

.enlace-tarifario:hover {
  color: #0056b3;
  text-decoration: underline;
}

.enlace-separator {
  color: #666;
}

/* Ajustes para dispositivos móviles */
@media (max-width: 767px) {
  .row {
    flex-direction: column-reverse;
  }

  .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .about-img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

/* Contenedor principal del componente */
#planificacion h2 {
  color: #333; /* Azul oscuro para un aspecto profesional */
  text-align: center;
  font-size: 36px; /* Tamaño ajustado para una mejor jerarquía visual */
  padding-bottom: 30px;
  text-transform: uppercase;
  margin-top: 50px;
}

/* Tarjetas de planificación */
.planning-card {
  background-color: #f9f9f9; /* Color de fondo más claro para las tarjetas */
  border-radius: 12px; /* Bordes más redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left; /* Alineación del texto a la izquierda */
}

.planning-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Títulos de servicio */
.planning-service-title {
  font-size: 1.6rem;
  color: #333; /* Azul oscuro para los títulos */
  margin-top: 15px;
  font-weight: bold; /* Negrita para los títulos */
}

/* Descripción de la tarjeta de planificación */
.planning-card-description {
  font-size: 1rem;
  color: #333; /* Color de texto más oscuro para mejorar la legibilidad */
  line-height: 1.6;
  text-align: justify; /* Justificar el texto para mejorar la lectura */
  margin-bottom: 10px; /* Espacio entre los párrafos */
}

/* Texto adicional desplegable */
.additional-text {
  font-size: 1rem;
  color: #555; /* Color de texto más suave para el texto adicional */
  margin-top: 10px; /* Espacio superior para separar el texto adicional */
}

/* Estilo para el enlace "Leer más" */
.read-more {
  color: #003366; /* Azul oscuro */
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
  margin-top: 10px;
}

.read-more:hover {
  color: #005599; /* Un tono más claro al pasar el cursor */
}

/* Estilo para el texto resaltado */
.bold-text {
  font-weight: bold;
  color: #003366; /* Azul oscuro para destacar */
}

/* Asegura que los elementos WOW tengan visibilidad */
.wow {
  visibility: hidden;
}

/* Media Queries para dispositivos móviles y tabletas */
@media (max-width: 767px) {
  .planning-card-description {
    font-size: 0.9rem; /* Ajuste de tamaño de fuente para móviles */
  }
}
